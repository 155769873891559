.login-logo { 
  padding-bottom: 40px;
  text-align: center;
}

.login-logo svg {
  max-width: 100%;
}

@media (min-width: 481px){
  .login-logo {
    padding-bottom: 64px;
  }
}


/* Table styles */
.table-main-name, .table-main-name-button {
  font-weight: bold;
  font-size: 16px;
}

.table-main-name {
  color: #172b4d;
}

.table-main-name-button {
  color: #004983;
}