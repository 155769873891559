.bdate::before {
	color: #999999;
	content: attr(placeholder);
}
.bdate {
	color: #ffffff;
}
.bdate:focus,
.bdate:valid {
	color: #666666;
}
.bdate:focus::before,
.bdate:valid::before {
	content: "" !important;
}
