.SnackbarItem-variantInfo {
  background-color: #0B75CB!important;
}

.SnackbarItem-variantWarning {
  background-color: #B35900!important;
}

.SnackbarItem-variantError{
  background-color: #D5190B!important;
}